<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNew">New Input Check</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable1"
      pagination
      
       >

       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

<template #fileId="{item}">
        <td v-if="item.fileId">
         <img :src="getImageUrl(item.fileId)" style='max-width:100px;max-height: 100px;'></td>
         <td v-else>--</td> 
    </template>
    
      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
   
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                 
             <CDropdownItem :to="{name: 'InputCheck', params: {checkId: item.checkId, mode: 'view'}}">View</CDropdownItem> 
                 <!-- <CDropdownItem :to="{name: 'InputField', params: {subSectionId: item.subSectionId}}">Input Checks</CDropdownItem>  -->
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteCheck(item.checkId)"
                    ></ConfirmationModal> 

                </CDropdown> 

        
        </td>
      </template> 
      </CDataTable>

  </CCardBody>
  <div ref="modalArea"></div>
  <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">

      <button type="button" class="btn btn-primary float-xl-right" @click="newTag">New Tag</button>
    </div>

    <CDataTable :items="details"  
    :fields="field"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable2"
      pagination
      
       >

       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
   
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                 
             <CDropdownItem :to="{name: 'InputTag', params: {tagId: item.tagId, mode: 'view'}}">View</CDropdownItem> 
                 <!-- <CDropdownItem :to="{name: 'InputField', params: {subSectionId: item.subSectionId}}">Input Checks</CDropdownItem>  -->
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteTag(item.tagId)"
                    ></ConfirmationModal> 

                </CDropdown> 

        
        </td>
      </template> 
      </CDataTable>

  </CCardBody>
  <div ref="modalArea"></div>
  <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
   { key: "checkName",label: "Check Name", _classes: 'font-weight-bold', _style: "min-width:200px" },
//  { key: "inputTypeName",label: "Input Type", _classes: 'font-weight-bold', _style: "min-width:200px" },
 { key: "fileId", label: "Image", _classes: 'font-weight-bold', _style: "min-width:200px" },
{ key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
const field = [
  'index',
   { key: "tagName",label: "Tag Name", _classes: 'font-weight-bold', _style: "min-width:200px" },
 
{ key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "InputChecks",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      field,


      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getOption() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/workPermit/sections/inputChecks/"+this.$route.params.inputFieldId)
        .then((response) => response.json())
        .then((data) => (
          console.log("options"+data),
          this.items = data));
    },
   
    getTags() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/workPermit/sections/inputTags/"+this.$route.params.inputFieldId)
        .then((response) => response.json())
        .then((data) => (this.details = data));
    },
     cancel() {
   
            // this.$router.push({ name: "InputFields", params: {sectionId: this.$route.params.sectionId }});
        window.history.back();
    },
    deleteCheck(checkId) {
      console.log("delete called with", checkId);
     this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/WorkPermit/section/InputCheck/"+ checkId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getOption();
             this.$nextTick(() => {
             this.$refs.vuetable1.refresh();
           });
         window.location.reload();
        });
    },
    deleteTag(tagId) {
      console.log("delete called with", tagId);
     this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/WorkPermit/section/InputTag/"+ tagId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getTags();
             this.$nextTick(() => {
            this.$refs.vuetable2.refresh();
          });
       //  window.location.reload();
        });
    },
       getImageUrl(fileId){
      return process.env.VUE_APP_API_HOST + '/emapp/file/'+fileId
        
    },
    navToNew(){
       this.$router.push({ name: "NewCheck", params: {sectionId: this.$route.params.sectionId, inputFieldId:this.$route.params.inputFieldId, mode: "new" } } );
    },
  
   newTag(){
       this.$router.push({ name: "InputTag", params: {sectionId: this.$route.params.sectionId,inputFieldId:this.$route.params.inputFieldId, mode: "new" } } );
    }
  },
  mounted() {
    this.getTags();
    this.getOption();
  },
};
</script>